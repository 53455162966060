import React from "react"

import styled, { css, keyframes } from "styled-components"

const Title = styled.h3`
  text-align: center;
`

const Divider = styled.hr`
margin: 1rem auto 1rem auto;
display: block;
height: 2px;
background: hsl(0, 0%, 86%);
width: 100%;
max-width: 100%
`

export const Skills = {
  html5: (
    <React.Fragment>
      <Title>HTML5</Title>
      <p>Pretty dang solid skill. Not much more to add.</p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>Creating a valid HTML5 structure</li>
        <li>Making a plain website in Notepad</li>
        <li>Finding obvious structure errors</li>
      </ul>
      <h6>No Can Do</h6>
      <ul>
        <li>Rattle off the history of the HTML standard</li>
        <li>List esoteric facts about past HTML versions</li>
      </ul>
    </React.Fragment>
  ),
  css: (
    <React.Fragment>
      <Title>CSS3</Title>
      <p>I can work with plain 'ol css, but I prefer to use SCSS for variables and because it makes writing classes for subcomponents
      much easier.</p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>Make a beautiful site using plain 'ol CSS</li>
        <li>Basic & mid-tier SCSS & LESS features</li>
        <li>Learn a new CSS framework</li>
      </ul>
      <h6>Can do with a bit of Google</h6>
      <ul>
        <li>Supporting really old browsers like IE 7, 8, or 9.</li>
      </ul>
      <h6>No Can Do</h6>
      <ul>
        <li>Create an entire new framework by myself</li>
      </ul>
    </React.Fragment>
  ),
  js: (
    <React.Fragment>
      <Title>Plain 'Ol Javascript</Title>
      <p>I have a good grasp on Javascript. No doubt there are a few gotchas that may bite me in the ass but for the most part
      I can handle most tasks pretty well, especially if I have access to Google.</p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>var vs let vs const</li>
        <li>Closures</li>
        <li>Classes/Modules</li>
        <li>Manipulating DOM</li>
        <li>Learning new libraries</li>
        <li>Using jQuery</li>
      </ul>
      <h6>Can do with a bit of Google</h6>
      <ul>
        <li>Learning new libraries</li>
      </ul>
      <h6>I want to learn how to</h6>
      <ul>
        <li>Make crazy stuff with three.js</li>
      </ul>
    </React.Fragment>
  ),
  reactSkill: (
    <React.Fragment>
      <Title>React</Title>
      <p>I would consider my React skills to be intermediate. I have no problem working on existing React codebases,
        have a solid  grasp on JSX, components, and good understanding of Redux. I can create a pretty complex application
        by myself but would prefer to have a senior dev to catch any bad practices/ideas before it goes to production.
      </p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>Create new complex components</li>
        <li>Use libraries like Material-UI</li>
        <li>Styled Components</li>
        <li>Redux w/ Thunk</li>
        <li>Integrate API with Axios, fetch, etc</li>
      </ul>
      <h6>Can do with a bit of Google</h6>
      <ul>
        <li>Learning new libraries</li>
        <li>Form validation, particularly with Formik</li>
      </ul>
      <h6>I want to learn how to</h6>
      <ul>
        <li>Use Redux Sagas</li>
      </ul>
    </React.Fragment>
  ),
  vue: (
    <React.Fragment>
      <Title>Vue</Title>
      <p>Another skill which I would consider intermediate. The only particular skill relating to Vue I don't have any knowledge
      of is Vuex, but I'm sure I can pick up the concept easily based off of Redux.</p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>Create new complex components</li>
        <li>Use Vue Router</li>
        <li>Integrate API with Axios, fetch, etc</li>
      </ul>
      <h6>Can do with a bit of Google</h6>
      <ul>
        <li>Learning new libraries</li>
      </ul>
      <h6>I want to learn how to</h6>
      <ul>
        <li>Use Vuex</li>
      </ul>
    </React.Fragment>
  ),
  laravel: (
    <React.Fragment>
      <Title>Laravel</Title>
      <p></p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>TBD</li>
      </ul>
      <h6>Can do with a bit of Google</h6>
      <ul>
        <li>TBD</li>
      </ul>
      <h6>I want to learn how to</h6>
      <ul>
        <li>TBD</li>
      </ul>
    </React.Fragment>
  ),
  wordpress: (
    <React.Fragment>
      <Title>Wordpress</Title>
      <p>While I'm definetly not an expert in Wordpress, I have done freelance work for a couple of clients. I have no
      problems starting up a theme from scratch (maybe using underscores as a skeleton). I'm pretty comfortable using ACF
      and adding options to the built-in WP customizer. If you need me to recreate something as comprehensive as say Divi or
      other popular themes from scratch, I might have trouble, but customizing those themes for a client won't be too hard either.</p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>Brochure websites</li>
        <li>Contact or other types of forms</li>
        <li>New post types for products etc</li>
        <li>Using custom loops outside of the main WP Loop</li>
      </ul>
      <h6>Can do with a bit of Google</h6>
      <ul>
        <li>Advanced features</li>
        <li>Custom Gutenberg blocks</li>
        <li>Use Wordpress API with a separate frontend (Gatsby or custom solution)</li>
      </ul>
      <h6>I want to learn</h6>
      <ul>
        <li>How to create really advanced themes with drag and drop features</li>
      </ul>
    </React.Fragment>
  ),
  linux: (
    <React.Fragment>
      <Title>Linux</Title>
      <p>I'm pretty comfortable with Linux and hosting in general. I can setup a LAMP or MERN stack on a fresh VPS pretty easily. Ubuntu
      and Debian are my most familiar Linux OSes.</p>
      <Divider/>
      <h6>Easy PZ</h6>
      <ul>
        <li>Setup LAMP stack on a VPS</li>
        <li>Setup simple IP Tables or UFW</li>
        <li>Setup Apache VirtualHosts for multiple sites on a single IP</li>
        <li>Simple reverse proxy with Apache</li>
        <li>Let's Encrypt for SSL</li>
      </ul>
      <h6>Can do with a bit of Google</h6>
      <ul>
        <li>Pretty much anything not relating to kernel level stuff</li>
      </ul>
      <h6>I want to learn how to</h6>
      <ul>
        <li>Use other Linux distributions or operating systems</li>
      </ul>
    </React.Fragment>
  ),
}