/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import "./layout.css"
import "./reset.css"

const Layout = ({ children }) => {

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}


export default Layout
