import React, { useEffect, useRef, useState } from "react"
import styled, { keyframes } from "styled-components"

const HomeInfoContainer = styled.div`
position: relative;
min-height: 75vh;
width: 100%;
overflow: hidden;
`

const FlyBy = keyframes`
from {
transform: translate3d(100vw, -50%, 0);
}
to {
transform: translate3d(-150vw, -50%, 0);
}
`
const SlideIn = keyframes`
from {
transform: translate3d(100vw, 0, 0);
}
to {
transform: translate3d(0, 0, 0);
}
`

const JetContainer = styled.div`
position: absolute;
z-index: 10;
width: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
#jet {
  fill: #c31432;
  width: 200px;
  height: auto;
  position: absolute;
  top: 50%;
  
  transform: translate3d(100vw, -50%, 0);
  animation-name: ${props => props.animate ? FlyBy : ""};
  animation-duration: 5s;
  animation-fill-mode: forwards;
}
`

const Background = styled.div`
position: absolute;
top: 0;
bottom: 0;
width: 100%;
background: #c31432;
z-index: 1;
transform: translate3d(100vw, -50%, 0);
animation-name: ${props => props.animate ? SlideIn : ""};
animation-duration: 2s;
animation-fill-mode: forwards;
animation-delay: 500ms;
`

const InfoContainer = styled.div`
color: #fff;
position: relative;
z-index: 10;
width: 100%;
text-align: center;
margin: 15vh 0 0 0;
h2 {
color: #fff;
}
svg {
height: 196px;
fill: #fff;
}
h5 {
 margin: 1rem 0 0 0;
}
a {
 color: #fff;
 font-family: "Open Sans", sans-serif;
 font-weight: 700;
  font-size: calc(1rem + 2.3vw);
  @media only screen and (min-width: 960px) {
    font-size: calc(.5rem + 1.9vw);
  }
 text-decoration: none;
 border: 5px solid transparent;
}
`
const Email = styled.a`
 color: #fff;
 font-family: "Open Sans", sans-serif;
 font-weight: 700;
 font-size: calc(.5rem + 1.9vw);
 text-decoration: none;
 &:hover {
  border-bottom: 5px solid #fff;
 padding-bottom: .5rem;
 }
`

const Icon = styled.div`
margin: 0 0 2rem 0;

@media only screen and (min-width: 960px) {
margin: 0 0 0 0;
}
`


const HomeInfo = (props) => {
  const [animate, setAnimate] = useState(false)
  const sectionRef = useRef()

  const handleScroll = (entries, observer) => {

    entries.forEach(entry => {
      if (entry.isIntersecting) {
        requestAnimationFrame((ts) => {
          setAnimate(true)
        })
        observer.unobserve(sectionRef.current)
      }

    })

  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: [.2],
    })
    observer.observe(sectionRef.current)
  }, [sectionRef])

  return (
    <HomeInfoContainer id="footer" ref={sectionRef}>
      <JetContainer animate={animate}>
        <svg version="1.1" id="jet" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             viewBox="0 0 100 69.1">
          <g>
            <path className="st0" d="M100,34.6c-2.2-2.1-14.2-2.6-14.2-2.6l-0.2-0.4l4.8-0.5v-4.5l-4.7,0.1l-0.2-0.8l5.1-0.9l0.6-10.4l-4.4-2.2
		L74.6,24.7l-4-0.1l6.2-24L69.1,0c0,0-21.5,23.7-23.1,25.4c-1.4,1.5-3.7,0.9-3.7,0.9l3.4-6.4h-2.1c0,0-1.5,1.1-4.4,3.3
		c-2.9,2.2-8,5.7-11.2,6.9c-3.2,1.2-13.6,1.4-17.6,1.9c-3.9,0.4-7,1-10.4,2.5c3.4,1.5,6.5,2.1,10.4,2.5c3.9,0.4,14.3,0.6,17.6,1.9
		c3.2,1.2,8.3,4.7,11.2,6.9c2.8,2.2,4.4,3.3,4.4,3.3h2.1l-3.4-6.4c0,0,2.3-0.6,3.7,0.9c1.6,1.7,23.1,25.4,23.1,25.4l7.7-0.6l-6.2-24
		l4-0.1l12.3,12.5l4.4-2.2l-0.6-10.4l-5.1-0.9l0.2-0.8l4.7,0.1v-4.5l-4.8-0.5l0.2-0.4C85.8,37.2,97.8,36.6,100,34.6L100,34.6
		L100,34.6z"/>
          </g>
        </svg>
      </JetContainer>
      <Background animate={animate}/>


      <InfoContainer>
        <div className="container fluid-mobile">
          <div className="grid">
            <div className="column">
              <Icon>
                <a href="#">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" role="img" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 384 512">
                    <path
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"></path>
                  </svg>
                  <h5>Resume</h5>
                </a>
              </Icon>


            </div>
            <div className="column">
              <Icon>
                <a href="https://www.linkedin.com/in/ishmaelavila/">
                  <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                  </svg>
                  <h5>LinkedIn</h5>
                </a>


              </Icon>

            </div>
          </div>

          <div className="grid">
            <div className="column">
              <Email href="mailto:hello@ishmaelavila.com">hello@ishmaelavila.com</Email>
            </div>
          </div>
        </div>


      </InfoContainer>
    </HomeInfoContainer>

  )
}

export default HomeInfo