import React, { useState } from "react"
import styled, { css, keyframes } from "styled-components"

const colors = {
  "blue": "linear-gradient(45deg, #004e92, #000428)",
  "purple": "linear-gradient(45deg, #333399, #ff00cc)",
  "orange": "linear-gradient(45deg, #ff512f, #f09819);",
  "yellow": "linear-gradient(45deg, #fcc942, #cc9400);",
  "sky": "linear-gradient(45deg, #56ccf2, #2f80ed);",
  "teal": "linear-gradient(45deg, #4c6c8c, #37946a);",
  "darkorange": "linear-gradient(45deg, #a33329, #451713);",
  "wordpress": "linear-gradient(45deg, #175875, #134359);",
  "none": "transparent",
}

const expand = keyframes`
from {
width: 10%;
opacity: 0;

}
to {
width: 100%;
opacity: 1;

}
`


const CardContainer = styled.div`
    -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  padding: .5rem 1rem;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  ${props => {
  if (props.expanded) {
    return css`
    position: absolute;
    z-index: 20;
    animation-name: ${expand};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    left: 0;
    right: 0;
    
    
    `
  }
}};
  transition: transform 400ms, width 1s;

  &:focus {
    transform: ${props => {
      return props.expanded ? "" : "translateY(-10px);";
    }};
    outline: none;
  }
  &:hover {
    transform: ${props => {
      return props.expanded ? "" : "translateY(-10px);";
    }};
  }
  
  .cardContent {
      position: relative;
       z-index: 2;
  }
 

`

const CardNudge = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  transform: translateY(-100%);
  transition: transform 400ms;
  background: ${props => colors[props.color]};
  ${CardContainer}:hover & {
    transform: translateY(-50%) skew(0, 7deg);
  }
  
    ${props => {
      if (props.expanded) {
        return "display: none;"
      }
    }
  };
  
`

const turnRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(90deg);
  }
`


const CardArrow = styled.div`
    width: 35px;
    height: 25px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: ${props => colors[props.color]};
    opacity: 0;
    transition: top 400ms, background-color 400ms;
    z-index: 2;
    ${CardContainer}:hover & {
      opacity: 1;
      top: 75%;
      animation-name: ${turnRight};
      animation-delay: 400ms;
      animation-duration: 400ms;
      animation-direction: normal;
      animation-fill-mode: forwards;
    };
    
  ${props => {
  if (props.expanded) {
    return "display: none;"
  }
}
};
`


//add more colors, match icon colors!!!

export default function Card(props) {
  const [expanded, setExpanded] = useState(false)


  const handleClick = (event) => {
    setExpanded(!expanded)
    if(expanded) {

      event.currentTarget.blur();
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setExpanded(!expanded)
    }
  }


  return (
    <CardContainer expanded={expanded} tabIndex={0} onClick={(e) => handleClick(e)}
                   onKeyPress={(e) => handleKeyPress(e)}>
      <CardNudge expanded={expanded} color={props.color}/>
      <CardArrow expanded={expanded} color={props.color}/>

      <div className="cardContent">
        {props.children}
        {expanded &&
        props.expandedContent
        }
      </div>
    </CardContainer>
  )
}